var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nk-content-body"},[_c('div',{staticClass:"nk-block-head nk-block-head-sm pb-2"},[_c('div',{staticClass:"nk-block-between"},[_c('div',{staticClass:"nk-block-head-content"},[_c('h3',{staticClass:"nk-block-title page-title"},[_vm._v("Accounts")]),_c('div',{staticClass:"nk-block-des text-soft"},[_c('p',[_vm._v("You have total "+_vm._s(_vm.accounts.length)+" accounts.")])])]),_c('div',{staticClass:"nk-block-head-content"},[_c('div',{staticClass:"toggle-wrap nk-block-tools-toggle"},[_vm._m(0),_c('div',{staticClass:"toggle-expand-content",attrs:{"data-content":"pageMenu"}},[_c('ul',{staticClass:"nk-block-tools g-3"},[_c('li',[_c('router-link',{staticClass:"btn btn-dark text-light",attrs:{"to":{name: 'account'}}},[_c('em',{staticClass:"icon ni ni-plus mr-1"}),_vm._v("Add")])],1)])])])])])]),_c('div',{staticClass:"nk-block"},[_c('div',{staticClass:"card p-2 card-stretch"},[_c('div',{staticClass:"card-inner-group"},[_c('div',{staticClass:"card-inner p-0"},[_c('div',{staticClass:"nk-tb-list nk-tb-ulist is-compact"},[(_vm.accounts)?_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.accounts,"styleClass":"vgt-table condensed tblhov","search-options":{ enabled: true, placeholder: 'Search Invoices'},"sort-options":{ enabled: true,},"pagination-options":{
                          enabled: true,
                          mode: 'records',
                          perPage: 50,
                          position: 'bottom',
                          perPageDropdown: [50,100,150],
                          dropdownAllowAll: false,
                          setCurrentPage: 1,
                          nextLabel: 'Next',
                          prevLabel: 'Prev',
                          rowsPerPageLabel: 'Rows per page',
                          ofLabel: 'of',
                          pageLabel: 'page', // for 'pages' mode
                          allLabel: 'All',
                      }},on:{"on-cell-click":_vm.goToAccount},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('div',[_c('a-popconfirm',{attrs:{"title":"Are you sure delete?","ok-text":"Yes","cancel-text":"No"},on:{"confirm":function($event){return _vm.deleteAccount(props)},"cancel":_vm.cancel}},[_c('button',{staticClass:"btn btn-sm mr-1 text-danger"},[_c('em',{staticClass:"icon ni ni-trash"})])])],1):(props.column.field == 'createdDate')?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$moment(props.row.createdDate).format('HH:mm a DD/MM/YYYY')))])]):_c('div',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,3103732161)}):_vm._e()],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"btn btn-icon btn-trigger toggle-expand mr-n1",attrs:{"href":"#","data-target":"pageMenu"}},[_c('em',{staticClass:"icon ni ni-menu-alt-r"})])}]

export { render, staticRenderFns }