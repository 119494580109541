<template>
<div class="nk-content-body">
  <div class="nk-block-head nk-block-head-sm pb-2">
      <div class="nk-block-between">
          <div class="nk-block-head-content">
              <h3 class="nk-block-title page-title">Accounts</h3>
              <div class="nk-block-des text-soft">
                  <p>You have total {{accounts.length}} accounts.</p>
              </div>
          </div><!-- .nk-block-head-content -->
          <div class="nk-block-head-content">
              <div class="toggle-wrap nk-block-tools-toggle">
                  <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-menu-alt-r"></em></a>
                  <div class="toggle-expand-content" data-content="pageMenu">
                      <ul class="nk-block-tools g-3">
                          <!-- <li><a href="#" class="btn btn-white btn-outline-light"><em class="icon ni ni-download-cloud"></em><span>Export</span></a></li> -->
                          <li><router-link :to="{name: 'account'}" class="btn btn-dark text-light"><em class="icon ni ni-plus mr-1"></em>Add</router-link></li>
                      </ul>
                  </div>
              </div><!-- .toggle-wrap -->
          </div><!-- .nk-block-head-content -->
      </div><!-- .nk-block-between -->
  </div><!-- .nk-block-head -->
  <div class="nk-block">
      <div class="card p-2 card-stretch">
          <div class="card-inner-group">
              <div class="card-inner p-0">
                  <div class="nk-tb-list nk-tb-ulist is-compact">
                    <vue-good-table v-if="accounts"
                      :columns="columns"
                      :rows="accounts"
                      @on-cell-click="goToAccount"
                      styleClass="vgt-table condensed tblhov"
                      :search-options="{ enabled: true, placeholder: 'Search Invoices'}"
                                          :sort-options="{ enabled: true,}"
                      :pagination-options="{
                          enabled: true,
                          mode: 'records',
                          perPage: 50,
                          position: 'bottom',
                          perPageDropdown: [50,100,150],
                          dropdownAllowAll: false,
                          setCurrentPage: 1,
                          nextLabel: 'Next',
                          prevLabel: 'Prev',
                          rowsPerPageLabel: 'Rows per page',
                          ofLabel: 'of',
                          pageLabel: 'page', // for 'pages' mode
                          allLabel: 'All',
                      }"
                    >
                    <template slot="table-row" slot-scope="props" >
                      <div v-if="props.column.field == 'actions'">
                        <a-popconfirm
                            title="Are you sure delete?"
                            ok-text="Yes"
                            cancel-text="No"
                            @confirm="deleteAccount(props)"
                            @cancel="cancel"
                        >
                            <button class="btn btn-sm mr-1 text-danger"><em class="icon ni ni-trash"/></button>
                        </a-popconfirm>
                      </div>
                      <div v-else-if="props.column.field == 'createdDate'">
                        <span>{{ $moment(props.row.createdDate).format('HH:mm a DD/MM/YYYY')}}</span>
                      </div>
                      <div v-else >
                        {{props.formattedRow[props.column.field]}}
                      </div>
                    </template>
                    </vue-good-table>
                  </div><!-- .nk-tb-list -->
              </div><!-- .card-inner -->
              
          </div><!-- .card-inner-group -->
      </div><!-- .card -->
  </div><!-- .nk-block -->
</div>
      
</template>

<script>

  export default {
    data () {
      return {
        search: '',
        columns: [
          { label: 'Account Number', field: 'id', width: '7%' },
          { label: 'Account Name', field: 'accountName' },
          { label: 'Phone', field: 'phoneNumber' },
          { label: 'Billing Contact', field: 'billingContact' },
          { label: 'Account Owner', field: 'accountOwner' },
          { label: 'Created Time', field: 'createdDate', width: '10%' },
          { label: 'Actions', field: 'actions', thClass: 'text-center', tdClass: 'text-center' }
        ],
        accounts: [],
      }
    },
    created() {
      this.getView()
      this.getAccounts()
    },
    methods: {
      getView (){
     this.$http.get('/ViewAccess/accounts')
        .then(() => { 
        })
        .catch(() => { 
        })
      },
      goToAccount(params){
        this.$router.push({ name: 'account', query: { accountId: params.row.id }  })
      },
      getAccounts(){
        this.$http.get('/accounts/get_Accounts')
        .then((response) => {
            this.accounts = response.data
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
      },
      deleteAccount(props) {
        this.$http.post('/accounts/Delete_Account', props.row)
        .then(() => {
            this.getAccounts()
            this.$message.success('Account Deleted')
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
      }
    }
  }
</script>

<style>

</style>